import '@elf/polyfills/minimal'

import '@elf/amber-loader'
import '@elf/amber-notification'
import '@elf/carbon-sidebar-layout'
import '@elf/coral-button'
import '@elf/coral-collapse'
import '@elf/coral-combo-box'
import '@elf/coral-header'
import '@elf/coral-icon'
import '@elf/coral-multi-input'
import '@elf/coral-panel'
import '@elf/coral-password-field'
import '@elf/coral-slider'
import '@elf/coral-split-button'
import '@elf/coral-text-field'
import '@elf/coral-tooltip'
import '@elf/emerald-autosuggest'
import '@elf/emerald-datetime-picker'
import '@elf/emerald-grid'
import '@elf/quartz-layout'

import '@elf/elf-theme-halo/light/imports/native-elements'
import '@elf/amber-loader/themes/halo/light'
import '@elf/amber-notification/themes/halo/light'
import '@elf/carbon-sidebar-layout/themes/halo/light'
import '@elf/coral-button/themes/halo/light'
import '@elf/coral-collapse/themes/halo/light'
import '@elf/coral-combo-box/themes/halo/light'
import '@elf/coral-header/themes/halo/light'
import '@elf/coral-icon/themes/halo/light'
import '@elf/coral-multi-input/themes/halo/light'
import '@elf/coral-panel/themes/halo/light'
import '@elf/coral-password-field/themes/halo/light'
import '@elf/coral-slider/themes/halo/light'
import '@elf/coral-split-button/themes/halo/light'
import '@elf/coral-text-field/themes/halo/light'
import '@elf/coral-tooltip/themes/halo/light'
import '@elf/emerald-autosuggest/themes/halo/light'
import '@elf/emerald-datetime-picker/themes/halo/light'
import '@elf/emerald-grid/themes/halo/light'
import '@elf/quartz-layout/themes/halo/light'

import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
