<template>
  <div id="app">
    <amber-loader v-if="!ready"></amber-loader>
    <template v-else>
      <template v-if="loggedOut">
        <coral-panel class="login" spacing>
          <quartz-layout flex container>
            <quartz-layout>
              <img class="logo" src="@/assets/refinitiv.logo.strapline.svg" alt="Logo">
            </quartz-layout>
            <!-- <quartz-layout>
              <coral-text-field ref="username" id="username" placeholder="Username" v-on:keyup="onKeyup"></coral-text-field>
            </quartz-layout>
            <quartz-layout>
              <coral-password-field ref="password" id="password" placeholder="Password" v-on:keyup="onKeyup"></coral-password-field>
            </quartz-layout>
            <quartz-layout class="right mb30">
              <coral-button class="large" cta @click="login">Login</coral-button>
            </quartz-layout> -->
            <quartz-layout>
              <h3>Logged out successfully!</h3>
            </quartz-layout>
            <quartz-layout>
              <p>If you want to re-login, please refresh the page!</p>
            </quartz-layout>
          </quartz-layout>
        </coral-panel>
      </template>
      <template v-if="loggedIn">
        <div class="overlay" v-if="loading">
          <amber-loader></amber-loader>
        </div>
        <header>
          <nav class="main">
            <ul>
              <li><a class="logo" href="#"><span>Refinitiv</span></a></li>
              <li class="logout"><coral-button class="large" cta @click="logout">Logout</coral-button></li>
            </ul>
          </nav>
        </header>
        <carbon-sidebar-layout ref="mainLayout" :sidebar-width="sidebarWidthPx">
          <coral-header slot="sidebar-header" level="1">Filters</coral-header>
          <coral-panel slot="sidebar-content">
            <FilterList :list="defaultList" ref="defaultFilterList" header="Default" :expanded="true" v-on:favorite-clicked="handleFavoriteClicked($event)"></FilterList>
            <FilterList :list="mrnList" ref="mrnFilterList" header="MRN" :expanded="false" v-on:favorite-clicked="handleFavoriteClicked($event)"></FilterList>
            <FilterList :list="rdpList" ref="rdpFilterList" header="RDP" :expanded="false" v-on:favorite-clicked="handleFavoriteClicked($event)"></FilterList>
            <coral-panel spacing>
              <coral-button class="float-right" cta @click="updateSelectedValues">Apply</coral-button>
              <coral-button cta @click="resetSelectedValues">Reset</coral-button>
            </coral-panel>
          </coral-panel>
          <coral-header slot="main-header" level="1">
            <coral-button transparent slot="left" ref="mainLayoutToggleButton" icon="leftpanel-open" v-on:click="mainLayoutToggleButtonClicked"></coral-button>
            <coral-button slot="right" :icon="readerDisplayed ? 'news-headlines-summary' : 'news'" transparent @click="toggleReader" title="Toggle News Reader"></coral-button>
          </coral-header>
          <coral-panel slot="main-content">
            <coral-panel>
              <quartz-layout flex>
                <quartz-layout class="white-bg" basis="30%">
                  <div ref="funnelGrid">
                    <table>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>Filters</th>
                          <th>Items</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in funnel" :key="index">
                          <td>{{ index === 0 ? '' : index }}</td>
                          <td>{{ item.filter }}</td>
                          <td class="right">{{ item.rows }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div ref="funnelChart" id="funnelChart"></div>
                </quartz-layout>
                <quartz-layout :basis="newsGridWidth">
                  <emerald-grid class="news-grid" ref="newsGrid"></emerald-grid>
                </quartz-layout>
                <quartz-layout :class="newsReaderClass" ref="newsReader" basis="40%" scrollable>
                  <coral-panel spacing v-if="selectedStory != null">
                    <template v-if="!headlineInBody || storyType === 'text'">
                      <h2>{{ selectedStory.headline }}</h2>
                      <ul class="news-metadata">
                        <li>{{ this.storyTimestamp }}</li>
                      </ul>
                    </template>
                    <iframe ref="storyFrame" class="story-frame" v-on:load="adjustFrameHeight" :srcdoc="documentHtml"></iframe>
                    <ul class="news-metadata">
                      <li v-if="selectedStory.keywords !== null && selectedStory.keywords.length > 0">
                        <label>Keywords:</label>
                        <coral-pill active v-for="(keyword, index) in selectedStory.keywords" :key="index">{{ keyword }}</coral-pill>
                      </li>
                      <li v-if="selectedStory.social_tags !== null && selectedStory.social_tags.length > 0">
                        <label>Social Tags:</label>
                        <coral-pill active v-for="(tag, index) in selectedStory.social_tags" :key="index">{{ tag }}</coral-pill>
                      </li>
                      <li v-if="selectedStory.sluglines !== null && selectedStory.sluglines.length > 0">
                        <label>Sluglines:</label>
                        <coral-pill active v-for="(slugline, index) in selectedStory.sluglines" :key="index">{{ slugline }}</coral-pill>
                      </li>
                    </ul>
                  </coral-panel>
                </quartz-layout>
              </quartz-layout>
            </coral-panel>
          </coral-panel>
        </carbon-sidebar-layout>
      </template>
    </template>
  </div>
</template>

<script>
import RowGrouping from 'tr-grid-row-grouping/es6/RowGrouping'
import RowSelection from 'tr-grid-row-selection/es6/RowSelection'
import ContentWrap from 'tr-grid-content-wrap/es6/ContentWrap'
import Plotly from 'plotly.js'
import dataServices from '@/services/dataServices'
import FilterList from '@/components/FilterList.vue'
import { error } from '@elf/amber-notification/lib/helpers'
import moment from 'moment'

export default {
  name: 'App',
  components: {
    FilterList
  },
  computed: {
    sidebarWidthPx () {
      return `${this.sidebarWidth}px`
    },
    numberList () {
      return Array.from(Array(10).keys())
    },
    defaultList () {
      return this.getFilterList(true, '')
    },
    mrnList () {
      return this.getFilterList(false, 'MRN')
    },
    rdpList () {
      return this.getFilterList(false, 'RDP')
    },
    documentHtml () {
      if (this.selectedStory == null) {
        return ''
      }
      if (this.storyType === 'text') {
        const regEx = new RegExp('\\n', 'gi')
        return this.selectedStory.body.replace(regEx, '<br>')
      }
      return this.selectedStory.body
    },
    pageOffset () {
      return (this.pageNumber - 1) * this.pageSize
    },
    newsGridWidth () {
      return this.readerDisplayed ? '30%' : '70%'
    },
    newsReaderClass () {
      return this.readerDisplayed ? 'news-reader' : 'news-reader hidden'
    },
    headlineInBody () {
      const bodyIndex = this.documentHtml.indexOf('<body')
      const headlineIndex = this.documentHtml.indexOf(this.selectedStory.headline, bodyIndex)
      return bodyIndex !== -1 && headlineIndex > bodyIndex
    }
  },
  watch: {
    readerDisplayed () {
      if (this.readerDisplayed) {
        this.adjustFrameHeight()
      }
    }
  },
  data () {
    return {
      ready: false,
      appName: 'rdp-news-demo',
      loading: false,
      loggedIn: false,
      loggedOut: false,
      sidebarWidth: 250,
      dateFormat: 'D MMMM YYYY HH:mm:ss ZZ',
      timeFormat: 'HH:mm:ss ZZ',
      filterMetaData: {
        date_range: {
          section: 'MRN',
          label: 'Date Range',
          linebreak: false,
          afterLinebreak: false,
          title: '',
          type: 'date',
          ref: 'dateRangePicker',
          placeholder: '',
          multiple: false,
          default: true,
          children: [],
          parent: null
        },
        all_topics: {
          section: 'MRN',
          label: 'Topics',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by topics',
          type: 'multi-input',
          ref: 'topicsCombo',
          placeholder: 'Choose a topic',
          multiple: true,
          default: true,
          children: [],
          parent: null
        },
        languages: {
          section: 'MRN',
          label: 'Languages',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by languages',
          type: 'multi-input',
          ref: 'languagesInput',
          placeholder: 'Choose a language',
          multiple: false,
          default: true,
          children: [],
          parent: null
        },
        geography: {
          section: 'MRN',
          label: 'Geography',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by geography',
          type: 'multi-input',
          ref: 'geographiesCombo',
          placeholder: 'Choose a geography',
          multiple: true,
          default: true,
          children: [],
          parent: null
        },
        asset_classes: {
          section: 'MRN',
          label: 'Asset Classes',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by asset classes',
          type: 'multi-input',
          ref: 'assetClassesCombo',
          placeholder: 'Choose an asset class',
          multiple: true,
          default: false,
          children: [],
          parent: null
        },
        sectors: {
          section: 'MRN',
          label: 'Sectors',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by sectors',
          type: 'multi-input',
          ref: 'sectorsCombo',
          placeholder: 'Choose a sector',
          multiple: true,
          default: false,
          children: [],
          parent: null
        },
        urgency: {
          section: 'MRN',
          label: 'Urgency',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by urgency',
          type: 'multi-input',
          ref: 'urgenciesInput',
          placeholder: '',
          multiple: false,
          default: false,
          children: [],
          parent: null
        },
        pub_status: {
          section: 'MRN',
          label: 'Publication Status',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by publication status',
          type: 'combo',
          ref: 'publicationStatusCombo',
          placeholder: 'Choose a publication status',
          multiple: true,
          default: false,
          children: [],
          parent: null
        },
        sources: {
          section: 'MRN',
          label: 'News Source',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by news source',
          type: 'combo',
          ref: 'newsSourceCombo',
          placeholder: 'Choose a news source',
          multiple: true,
          default: false,
          children: [],
          parent: null
        },
        companies: {
          section: 'MRN',
          label: 'Companies',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by companies',
          type: 'multi-input',
          ref: 'companiesCombo',
          placeholder: 'Choose a company',
          multiple: true,
          default: true,
          children: [],
          parent: null
        },
        named_products: {
          section: 'MRN',
          label: 'Named Product',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by named product',
          type: 'multi-input',
          ref: 'namedProductsCombo',
          placeholder: 'Choose a named product',
          multiple: true,
          default: false,
          children: [],
          parent: null
        },
        recurring_reports: {
          section: 'MRN',
          label: 'Recurring Reports (RR)',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by reports',
          type: 'multi-input',
          ref: 'recurringReportsCombo',
          placeholder: 'Choose an option',
          multiple: true,
          default: false,
          children: [],
          parent: null
        },
        roles: {
          section: 'MRN',
          label: 'Role',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter news articles by role',
          type: 'multi-input',
          ref: 'rolesInput',
          placeholder: '',
          multiple: false,
          default: false,
          children: [],
          parent: null
        },
        subject_taggers: {
          section: 'RDP',
          label: 'Subject Tagger',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by news articles by tagger',
          type: 'multi-input',
          ref: 'subjectTaggersInput',
          placeholder: '',
          multiple: false,
          default: false,
          children: [],
          parent: null
        },
        subject_relevance: {
          section: 'RDP',
          label: 'Subject Relevance',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by news articles by relevance',
          type: 'multi-input',
          ref: 'subjectRelevancesInput',
          placeholder: '',
          multiple: false,
          default: false,
          children: [],
          parent: null
        },
        subject_confidence: {
          section: 'RDP',
          label: 'NLP Confidence',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by news articles by confidence',
          type: 'slider',
          ref: 'subjectRelevancesSlider',
          placeholder: '',
          multiple: false,
          default: false,
          children: [],
          parent: null
        },
        readership_significant: {
          section: 'RDP',
          label: 'Readership',
          linebreak: true,
          afterLinebreak: false,
          title: '',
          type: 'checkbox',
          ref: 'readershipCheckbox',
          placeholder: '',
          multiple: false,
          default: false,
          children: [],
          parent: null
        },
        sentiment: {
          section: 'RDP',
          label: 'Sentiment',
          linebreak: false,
          afterLinebreak: false,
          title: '',
          type: 'multi-input',
          ref: 'sentimentsInput',
          placeholder: '',
          multiple: false,
          default: false,
          children: ['sentiment_doc_score', 'sentiment_company_score'],
          parent: null
        },
        sentiment_doc_score: {
          section: 'RDP',
          label: 'Document Score',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by document score',
          type: 'slider',
          ref: 'sentimentDocumentScoreSlider',
          placeholder: '',
          multiple: false,
          default: false,
          children: [],
          parent: 'sentiment'
        },
        sentiment_company_score: {
          section: 'RDP',
          label: 'Company Score',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by company score',
          type: 'slider',
          ref: 'sentimentCompanyScoreSlider',
          placeholder: '',
          multiple: false,
          default: false,
          children: [],
          parent: 'sentiment'
        },
        clustering: {
          section: 'RDP',
          label: 'Deduplication',
          linebreak: false,
          afterLinebreak: false,
          title: 'Remove duplicated articles',
          type: '',
          ref: '',
          placeholder: '',
          multiple: false,
          default: false,
          children: ['deduplication_within_sources', 'deduplication_across_sources'],
          parent: null
        },
        deduplication_across_sources: {
          section: 'RDP',
          label: 'Across Sources',
          linebreak: true,
          afterLinebreak: false,
          title: '',
          type: 'checkbox',
          ref: 'clusteringAcrossSourcesCheckbox',
          placeholder: '',
          multiple: true,
          default: false,
          children: [],
          parent: 'clustering'
        },
        deduplication_within_sources: {
          section: 'RDP',
          label: 'Within Sources',
          linebreak: true,
          afterLinebreak: false,
          title: '',
          type: 'checkbox',
          ref: 'clusteringWithinSourcesCheckbox',
          placeholder: '',
          multiple: true,
          default: false,
          children: [],
          parent: 'clustering'
        },
        symbology_firm_type: {
          section: 'RDP',
          label: 'Symbology',
          linebreak: false,
          afterLinebreak: false,
          title: 'Filter by firm type',
          type: 'split-button',
          ref: 'firmTypeSplitButton',
          placeholder: '',
          multiple: false,
          default: false,
          children: ['symbology_include_parent', 'symbology_include_subsidiary'],
          parent: null
        },
        symbology_include_parent: {
          section: 'RDP',
          label: 'Parent Company',
          linebreak: true,
          afterLinebreak: true,
          title: 'Include parent companies in the company list',
          type: 'split-button',
          ref: 'includeParentSplitButton',
          placeholder: '',
          multiple: false,
          default: false,
          children: [],
          parent: 'symbology_firm_type'
        },
        symbology_include_subsidiary: {
          section: 'RDP',
          label: 'Subsidiary Company',
          linebreak: true,
          afterLinebreak: false,
          title: 'Include subsidiary companies in the company list',
          type: 'split-button',
          ref: 'includeSubsidiarySplitButton',
          placeholder: '',
          multiple: false,
          default: false,
          children: [],
          parent: 'symbology_firm_type'
        }
      },
      selectedStory: null,
      stories: [],
      funnel: [],
      newsSource: null,
      storyTimestamp: null,
      storyType: null,
      pageSize: 100,
      pageNumber: 1,
      selectedValues: [],
      readerDisplayed: false,
      userFavorites: [],
      userFavoritesKey: 'user-favorites',
      defaultFavorites: ['date_range', 'all_topics', 'languages', 'geography', 'companies'],
      rowGrouping: null
    }
  },
  methods: {
    onKeyup (event) {
      if (event.target.id === 'username' && event.key === 'Enter') {
        this.$refs.password.focus()
      }
      else if (event.target.id === 'password' && event.key === 'Enter') {
        this.login()
      }
    },
    redirectUserLogin() {
      dataServices.go2safe()
    },
    fetchResponse(){
      let uri = window.location.href.split('/');
      if(uri.length>4 && !this.loggedIn){
        dataServices.fetchJWT(uri[4],uri[5]).then((data) => {
          // console.log(data["data"])
          if(data["data"]["d"]){
            this.jwt = data["data"]["d"]["jwt"]
            localStorage.setItem('jwt', this.jwt)
            this.extract(this.jwt)
            window.location.href = uri[0]+"//"+uri[2]
          }
        })
      }
    },
    extract(jwt) {
      let parts = jwt.split('.')
      let bodyEnc = parts[1]
      let base64 = bodyEnc.replace(/-/g, '+').replace(/_/g, '/')
      if (!base64) {
        return
      }
      let bodyStr = atob(base64)
      let body
      try {
          body = JSON.parse(bodyStr)
      }
      catch (e) {
        body = {}
      }
      let exp = body.exp
      // console.log(exp)
      let user = body.usr
      // console.log(user)

      if (!this.isExpired(exp)) {
        this.currentUser = user
        this.loggedIn = true
        this.dismissNotification()
      }
      else {
        this.currentUser = null
        this.loggedIn = false
        localStorage.removeItem('jwt')
        this.redirectUserLogin()
      }
    },
    isExpired(exp) {
        if (!exp) return true;
        let now = Date.now();
        return now >= exp * 1000;
    },
    login () {
      const regEx = RegExp(/[a-zA-Z0-9]+\.[a-zA-Z0-9]+@(?:lseg|refinitiv)\.com$/gm)
      if (regEx.test(this.$refs.username.value) && this.$refs.password.value === 'isituseful?') {
        this.loggedIn = true
        localStorage.setItem(`jwt`, new Date().getTime())
        this.dismissNotification()
        // Usage tracking
      }
      else {
        error('Invalid username or password')
      }
    },
    checkLogin () {
      const jwt = localStorage.getItem(`jwt`);
      if (jwt == null) {
        let uri = window.location.href.split('/');
        if(uri.length>4){
          this.fetchResponse()
        }else{
          this.redirectUserLogin()
        }
      }else{
        this.extract(jwt)
      }
    },
    logout() {
        this.currentUser = null
        this.loggedIn = false
        this.loggedOut = true
        localStorage.removeItem('rdp-news-demo-jwt')
    },
    dismissNotification () {
      const notification = document.querySelector('amber-notification-tray')
      if (notification !== null) {
        const parent = notification.parentElement
        const html = document.querySelector('html')
        parent.removeChild(notification)
        html.style.removeProperty('padding-top')
        html.style.removeProperty('box-sizing')
      }
    },
    mainLayoutToggleButtonClicked () {
      const width = (window.innerWidth - (this.$refs.mainLayout.collapsed ? this.sidebarWidth : 0)) * 0.3
      Plotly.relayout('funnelChart', {
        width
      })
      this.$refs.mainLayout.collapsed = !this.$refs.mainLayout.collapsed
      this.$refs.mainLayoutToggleButton.setAttribute('icon', this.$refs.mainLayout.collapsed ? 'leftpanel-closed' : 'leftpanel-open')
    },
    adjustChartWidth () {
      const width = (window.innerWidth - (this.$refs.mainLayout.collapsed ? 0 : this.sidebarWidth)) * 0.3
      Plotly.relayout('funnelChart', {
        width
      })
    },
    renderGrid () {
      const rowSelection = new RowSelection()
      const rowGrouping = new RowGrouping()
      const columns = [
        { title: 'Timestamp', field: 'timestamp', sortBy: 'timestamp', width: 140 },
        { title: 'News Source', field: 'source', sortBy: 'source', width: 120 },
        { title: 'Headline', field: 'headline', sortBy: 'headline', minWidth: 150, contentWrap: true },
        { title: 'Story ID', field: 'storyId', visible: false },
        { title: 'Year', field: 'year', visible: false },
        { title: 'Month', field: 'month', visible: false },
        { title: 'Day', field: 'day', visible: false },
        { title: 'Date', field: 'date', visible: false }
      ]
      const config = {
        sorting: {
          sortableColumns: true
        },
        columns,
        rowGrouping: {
          groupBy: ['year', 'month', 'day'],
          headerBinding: (event) => {
            event.cell.setContent(`${event.groupId} (${event.dataSource.getAllRowIds().length})`)
          }
        },
        dataModel: {
          fields: columns.map(column => column.field),
          data: this.stories.map(story => [
            moment(story.timestamp).format(this.timeFormat),
            story.source,
            story.headline,
            story.story_id,
            moment(story.timestamp).format('YYYY'),
            moment(story.timestamp).format('MMMM'),
            moment(story.timestamp).format('D MMMM'),
            moment(story.timestamp).format(this.dateFormat)
          ])
        },
        rowSelection: {
          basedOnContent: true,
          selectionChanged: (event) => {
            const selectedRow = event.api.getDataRows(event.selectedRows)[0]
            console.log(selectedRow)
            this.retrieveStory(selectedRow.storyId, selectedRow.source, selectedRow.date)
          }
        },
        extensions: [rowGrouping, rowSelection, new ContentWrap()],
        whenDefined: (event) => {
          const vScrollbar = event.api.getCoreGrid().getVScrollbar()
          vScrollbar.listen('scroll', this.onGridScrollEnd)
        }
      }
      if (this.$refs.newsGrid) {
        this.rowGrouping = rowGrouping
        this.$refs.newsGrid.config = config
      }
    },
    onGridScrollEnd () {
      const vScrollbar = this.$refs.newsGrid.api.getCoreGrid().getVScrollbar()
      if (vScrollbar.isEndOfVerticalScroll()) {
        //console.log('Request additional data')
        this.pageNumber++
        this.retrieveData(true)
      }
    },
    updateGridData (data) {
      const dataTable = this.$refs.newsGrid.api.getDataTable()
      const rows = data.map(story => {
        return {
          timestamp: moment(story.timestamp).format(this.timeFormat),
          source: story.source,
          headline: story.headline,
          storyId: story.story_id,
          year: moment(story.timestamp).format('YYYY'),
          month: moment(story.timestamp).format('MMMM'),
          day: moment(story.timestamp).format('D MMMM'),
          date: moment(story.timestamp).format(this.dateFormat)
        }
      })
      dataTable.addRows(rows)
    },
    renderLogBarChart () {
      const x = []
      const y = []
      const customdata = []
      const complete = parseInt(this.funnel[0].rows.replace(/,/gi, ''))
      this.funnel.forEach((item) => {
        const value = parseInt(item.rows.replace(/,/gi, ''))
        y.push(`${item.filter}`)
        x.push(value)
        customdata.push([value / complete])
      })
      const data = [
        {
          x,
          y: [...Array(this.funnel.length).keys()].map(item => `${item.toString()}:`),
          type: 'bar',
          text: y,
          textposition: 'none',
          customdata,
          hovertemplate: `<b>%{text}</b><br>%{x}<br>%{customdata[0]:.0%}<extra></extra>`,
          mode: 'markers',
          orientation: 'h',
          marker: {
            color: [
              '#334BFF', '#000000', '#7F6400', '#71549F', '#007678',
              '#CC4000', '#007653', '#D22962', '#595959', '#236F99',
              '#147070', '#AF583A', '#0F1E8A', '#737373', '#4C3C00',
              '#563F77', '#004E4F', '#993000', '#00432F', '#A3204C',
              '#404040', '#174A66', '#116262', '#88442D', '#5D122B'
            ]
          }
        }
      ]
      const layout = {
        margin: { l: 30, r: 0 },
        xaxis: {
          type: 'log',
          autorange: true
        },
        yaxis: {
          autorange: 'reversed'
        }
      }
      const config = {
        responsive: true
      }
      Plotly.newPlot('funnelChart', data, layout, config)
    },
    initializeFilters () {
      const keys = Object.keys(this.filterMetaData)
      keys.forEach(key => {
        const filter = this.filterMetaData[key]
        if (filter.data) {
          const component = document.getElementById(filter.ref)
          if (component != null) {
            component.data = filter.data
          }
        }
      })
    },
    async retrieveMetaData () {
      this.loading = true
      const response = await dataServices.retrieveMetaData()
      //console.log(response.data)
      await Promise.all(
        response.data.map(item => {
          return dataServices.retrieveMetaDataOptions(item)
        })
      ).then(responses => {
        const filters = []
        //console.log(responses)
        const selectedValues = []
        responses.forEach(response => {
          //console.log(response.data)
          const filter = this.filterMetaData[response.data.item]
          //console.log(filter)
          const values = []
          switch (filter.type) {
            case 'checkbox':
              response.data.options.forEach(option => {
                values.push({
                  id: option.code,
                  value: false,
                  label: option.name
                })
              })
              filter.values = values
              break
            case 'combo':
              response.data.options.forEach(option => {
                values.push({
                  value: option.code,
                  label: option.name,
                  selected: false
                })
              })
              filter.data = values
              break
            case 'date':
              response.data.options.forEach(option => {
                switch(option.name) {
                  case 'start_date':
                    filter.min = option.code
                    break
                  case 'end_date':
                    filter.max = option.code
                    break
                }
              })
              filter.values = response.data.default_values
              selectedValues.push({
                item: response.data.item,
                options: response.data.default_values
              })
              break
            case 'multi-input':
              response.data.options.forEach(option => {
                values.push({
                  id: option.code,
                  value: response.data.default_values.indexOf(option.code) !== -1,
                  label: option.name
                })
              })
              filter.data = values
              filter.selected = values.filter(item => item.value)
              selectedValues.push({
                item: response.data.item,
                options: response.data.default_values
              })
              break
            case 'slider':
              response.data.options.forEach(option => {
                switch(option.name) {
                  case 'min':
                    filter.min = option.code
                    break
                  case 'max':
                    filter.max = option.code
                    break
                  case 'step':
                    filter.step = option.code
                    break
                }
              })
              filter.from = response.data.default_values[0]
              filter.to = response.data.default_values[1]
              selectedValues.push({
                item: response.data.item,
                options: response.data.default_values
              })
              break
            case 'split-button':
              response.data.options.forEach((option) => {
                values.push({
                  id: option.code,
                  value: response.data.default_values == option.code,
                  label: option.name
                })
              })
              filter.values = values
              selectedValues.push({
                item: response.data.item,
                options: [response.data.default_values]
              })
              break
          }
          filter.defaultValues = response.data.default_values
          filter.default = this.defaultFavorites.indexOf(response.data.item) !== -1 || this.userFavorites.indexOf(response.data.item) !== -1
          filters.push(filter)
        })
        console.log(filters)
        this.selectedValues = selectedValues
      })
      this.loading = false
    },
    cleanHtmlTags (input) {
      let output = input
      output = output.replace(/<[^>]*>/g, ' ')
      output = output.replace(/\s{2,}/g, ' ')
      output = output.trim()
      return output
    },
    async retrieveData (append) {
      this.loading = true
      const response = await dataServices.retrieveData(this.pageSize, this.pageOffset, this.selectedValues)
      //console.log(response.data)
      if (response.status === 'OK') {
        const stories = response.data.data.map((story) => {
          story.headline = this.cleanHtmlTags(story.headline)
          return story
        })
        if (append === undefined) {
          this.stories = stories
          this.funnel = response.data.funnel
          this.renderLogBarChart()
          this.renderGrid()
          this.$nextTick(() => {
            this.retrieveStory()
          })
        }
        else {
          this.stories = this.stories.concat(stories)
          this.updateGridData(stories)
        }
      }
      else {
        error('Data retrieval failed')
      }
      this.loading = false
    },
    async retrieveStory (storyId, source, timestamp) {
      if (storyId === undefined) {
        if (this.stories.length > 0) {
          const selectedStory = this.stories[0]
        //storyId = 'urn:newsml:reuters.com:20221231:nL1N33L0CL'
          storyId = selectedStory.story_id
          source = selectedStory.source
          timestamp = selectedStory.timestamp ? moment(selectedStory.timestamp).format(this.dateFormat) : selectedStory.timestamp
        }
      }
      if (storyId) {
        this.loading = true
        const response = await dataServices.retrieveStory(storyId)
        //console.log(response)
        if (response.status === 'OK') {
          if (response.data.status_code === 200) {
            this.selectedStory = response.data
            this.newsSource = source
            this.storyTimestamp = timestamp
            this.storyType = response.data.body_type
          }
          else {
            error('Invalid news story')
          }
        }
        else {
          error('New story retrieval failed')
        }
        this.loading = false
      }
    },
    adjustFrameHeight () {
      const frame = this.$refs.storyFrame
      frame.contentDocument.body.style.fontFamily = 'Avenir, Helvetica, Arial, sans-serif'
      frame.height = `${frame.contentDocument.documentElement.offsetHeight}px`
      setTimeout(() => {
        frame.height = `${frame.contentDocument.documentElement.offsetHeight + 20}px`
      }, 100)
      this.$refs.newsReader.scrollTo(0, 0)
    },
    updateSelectedValues () {
      const selectedValues = []
      Object.keys(this.filterMetaData).forEach(key => {
        const filter = this.filterMetaData[key]
        let selected = []
        let element
        switch (filter.type) {
          case 'checkbox':
            filter.values.forEach(checkbox => {
              element = document.getElementById(`${filter.ref}-${checkbox.id}`)
              if (element.checked) {
                selected.push(checkbox.id)
              }
            })
            break
          case 'combo':
            element = document.getElementById(`${filter.ref}`)
            if (element) {
              selected = element.values
            }
            break
          case 'date':
            element = document.getElementById(`${filter.ref}`)
            if (element) {
              selected = element.values
            }
            break
          case 'multi-input':
            element = document.getElementById(`${filter.ref}MultiInput`)
            if (element) {
              selected = element.values
            }
            break
          case 'slider':
            element = document.getElementById(`${filter.ref}`)
            if (element) {
              selected.push(parseFloat(element.from))
              selected.push(parseFloat(element.to))
            }
            break
          case 'split-button':
            filter.values.forEach(button => {
              element = document.getElementById(`${filter.ref}-${button.id}`)
              if (element.active) {
                selected.push(button.id)
              }
            })
            break
        }
        if (selected.length > 0) {
          selectedValues.push({
            item: key,
            options: selected
          })
        }
      })
      //console.log(selectedValues)
      this.selectedValues = selectedValues
      this.pageNumber = 1
      this.retrieveData()
    },
    getFilterList (favorite, name) {
      const list = []
      const favorites = []
      Object.keys(this.filterMetaData).forEach(key => {
        const filter = this.filterMetaData[key]
        let condition = filter.section === name && !filter.default
        filter.key = key
        if (favorite) {
          condition = filter.default
        }
        if (condition && filter.parent === null) {
          if (filter.children.length > 0) {
            filter.filters = []
            filter.children.forEach(child => {
              const subFilter = this.filterMetaData[child]
              filter.filters.push(subFilter)
            })
          }
          list.push(filter)
          if (favorite) {
            favorites.push(key)
          }
        }
      })
      if (favorites.length > 0) {
        //console.log(favorites)
        this.saveUserFavortites(favorites)
      }
      return list
    },
    handleFavoriteClicked (event) {
      const key = event.key
      //console.log(key)
      this.filterMetaData[key].default = !this.filterMetaData[key].default
    },
    toggleReader () {
      const groupBy = ['year', 'month', 'day']
      this.readerDisplayed = !this.readerDisplayed
      this.$refs.newsGrid.api.hideColumn(0, this.readerDisplayed)
      if (this.readerDisplayed) {
        groupBy.push('timestamp') 
      }
      this.rowGrouping.groupBy(groupBy)
    },
    resetSelectedValues () {
      this.$refs.defaultFilterList.initializeFilters()
      this.$refs.mrnFilterList.initializeFilters()
      this.$refs.rdpFilterList.initializeFilters()
    },
    saveUserFavortites (favorites) {
      if (JSON.stringify(favorites) != JSON.stringify(this.defaultFavorites)) {
        localStorage.setItem(this.userFavoritesKey, JSON.stringify(favorites))
      }
    },
    loadUserFavorites () {      
      const favorites = localStorage.getItem(this.userFavoritesKey)
      if (favorites !== null) {
        this.userFavorites = JSON.parse(favorites)
      }      
    }
  },
  async mounted () {
    window.addEventListener('resize', this.adjustChartWidth)
    this.checkLogin()
    if(this.loggedIn){
      this.loadUserFavorites()
      await this.retrieveMetaData()
      localStorage.setItem('appId', this.appName)
      this.ready = true
      this.retrieveData()
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
header, nav {
  margin: 0;
  padding: 0;
  font-size: 15px;
  width: 100%;
  overflow: visible;
}
header {
  height: 79px;
  background-color: #fff;
}
nav.main {
  background-color: #ffffff;
  /*border-bottom:  1px solid #404040;*/
}
nav.main ul {
  margin: -10px 0 0 0;
  padding: 0;
}
nav.main li {
  list-style: none;
  display: inline-block;
  overflow: visible;
}
nav.main a {
  color: #404040;
  display: inline-block;
  padding: 20px;
  text-decoration: none;
  vertical-align: bottom;
  box-sizing: content-box;
  border-bottom:  5px solid #fff;
}
nav.main a:hover {
  border-bottom:  5px solid #001eff;
}
nav.main a.active {
  color: #404040;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  font-weight: 700;
  outline: 0;
  border-bottom:  5px solid #001eff;
}
nav.main a.logo {
  width: 155px;
  height:  40px;
  display: inline-block;
  background-color: transparent;
  border-radius: 0;
  margin-right: 50px;
  border-bottom:  5px solid #fff;
}
a.logo span {
  display: inline-block;
  background-image: url(assets/refinitiv.logo.strapline.svg);
  background-repeat: no-repeat;
  background-size: 80%;
  text-indent: -9999px;
  width: 198px;
  height: 78px;
}
coral-panel.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  /*height: 500px;*/
  margin: 15% auto;
  border: solid 1px #e1e1e1;
  /* From XD */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.login .logo {
  margin: 30px;
  height: 60px;
}
.login coral-text-field, .login coral-password-field {
  height: 33px;
  width: 100%;
  margin-bottom: 15px;
}
.login coral-button.large {
  /*background-color: #001EFF;*/
  height: 33px;
  min-width: 100px;
  font-size: 120%;
  color: #FFFFFF;
}
carbon-sidebar-layout {
  height: calc(100vh - 79px);
}
.news-grid, .news-reader {
  /*height: calc(100vh - 490px);*/
  height: calc(100vh - 110px)
}
ul.news-metadata {
  list-style-type: none;
  margin: 0;
  padding: 0 0 20px 0;
}
ul.news-metadata li {
  display: inline-block;
  margin-right: 10px;
}
.news-reader img {
  width: 100%;
  margin-bottom: 10px;
}
.story-frame {
  border: 0;
  width: 100%;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
  cursor: pointer;
}
.white-bg {
  background-color: #ffffff;
}
.right {
  text-align: right;
}
.hidden {
  display: none;
}
.float-right {
  float: right;
}
.mb30 {
  margin-bottom: 30px;
}
.logout{
  float: right;
  margin-top: 40px;
  margin-right: 20px;
}
</style>
