<template>
  <div>
    <coral-multi-input :id="name + 'MultiInput'" ref="multiInput" :placeholder="placeholder"></coral-multi-input>
    <emerald-autosuggest :attach="'#' + name + 'MultiInput'" ref="autoSuggest" request-on-focus></emerald-autosuggest>
  </div>
</template>

<script>
export default {
  name: 'AutoSuggestMultiInput',
  props: {
    options: Array,
    placeholder: String,
    name: String,
    selected: Array
  },
  data () {
    return {
      pageSize: 500,
      currentPage: 1,
      suggestions: []
    }
  },
  methods: {
    initializeComponents () {
      const autoSuggest = this.$refs.autoSuggest
      const multiInput = this.$refs.multiInput

      this.selected.forEach(item => {
        multiInput.add({
          value: item.id,
          label: item.label
        })
      })
      //console.log(multiInput.values)

      multiInput.addEventListener('item-added', (event) => {
        event.preventDefault()

        const items = this.options.filter((item) => {
          return item.label.toLowerCase().indexOf(event.detail.item.value.toLowerCase()) !== -1
        })
        if (items.length > 0 && multiInput.values.indexOf(items[0].id) === -1) {
          multiInput.add({
            value: items[0].id,
            label: items[0].label
          })
        }
        multiInput.value = ''
      })

      autoSuggest.addEventListener('add-attach-target-events', (ev) => {
        ev.preventDefault()

        // add event listeners to the input. You do not have to implement all
        // pipeline that run on input value change
        multiInput.addEventListener('value-changed', autoSuggest.onInputValueChange)
        // pipeline that run on key down
        multiInput.addEventListener('keydown', autoSuggest.onInputKeyDown)
        // pipeline that run on input focus
        multiInput.addEventListener('focus', autoSuggest.onInputFocus)
        // pipeline that run on input blur
        multiInput.addEventListener('blur', autoSuggest.onInputBlur)
      })

      autoSuggest.addEventListener('remove-attach-target-events', (ev) => {
        ev.preventDefault()

        // do not forget to remove event listeners on destroy
        multiInput.removeEventListener('value-changed', autoSuggest.onInputValueChange)
        multiInput.removeEventListener('keydown', autoSuggest.onInputKeyDown)
        multiInput.removeEventListener('focus', autoSuggest.onInputFocus)
        multiInput.removeEventListener('blur', autoSuggest.onInputBlur)
      })

      autoSuggest.addEventListener('suggestions-query', (ev) => {
        ev.preventDefault()

        // If Enter is pressed, add the value stright away
        if (ev.detail.reason === 'enter-pressed' && multiInput.value) {
          if (multiInput.values.indexOf(multiInput.value) === -1) {
            multiInput.add({
              value: multiInput.value,
              label: multiInput.value
            })
          }
        }

        // Otherwise call for suggestions
        autoSuggest.query = multiInput.value
      })

      autoSuggest.addEventListener('item-select', (ev) => {
        ev.preventDefault()

        const suggestion = ev.detail.suggestion
        const method = ev.detail.method
        const query = ev.detail.query

        switch (method) {
          case 'click':
          case 'enter':
            if (multiInput.values.indexOf(suggestion.id) === -1) {
              multiInput.add({
                value: suggestion.id,
                label: suggestion.label
              })
            }

            multiInput.value = ''
            autoSuggest.moreResults = false
            break
          case 'navigation':
            multiInput.value = suggestion.label
            break
          case 'clear':
            multiInput.value = ''
            break
          case 'reset':
            multiInput.value = query
            break
          default:
            // no default
        }
      })

      autoSuggest.addEventListener('suggestions-fetch-requested', (event) => {
        const query = event.detail.query
        const reason = event.detail.reason
        if (reason === 'more-results') {
          this.currentPage += 1
        }
        else {
          this.currentPage = 1
          this.suggestions = []
        }
        const suggestions = query ? this.options.filter((item) => {
          return item.label.toLowerCase().indexOf(query.toLowerCase()) !== -1
            && multiInput.values.indexOf(item.id) === -1
        }) : this.options.filter((item) => {
          return multiInput.values.indexOf(item.id) === -1
        })
        const page = suggestions.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        this.suggestions = this.suggestions.concat(page)
        autoSuggest.moreResults = suggestions.length > this.currentPage * this.pageSize
        autoSuggest.suggestions = this.suggestions
      })
    }
  },
  mounted () {
    this.initializeComponents()
  }
}
</script>
<style scoped>
emerald-autosuggest {
  width: 225px !important;
}
</style>