<template>
  <coral-collapse :header="header" level="2" spacing :expanded="expanded">
    <ul class="input-panel">
      <li v-for="(filter, filterIndex) in list" :key="filterIndex">
        <coral-button class="favorites" :icon="filter.default ? 'favorites-filled': 'favorites'" :title="!filter.default ? 'Add filter to default list': 'Remove filter from default list'" @click="favoriteButtonClicked(filter.key)" transparent></coral-button>
        <template v-if="filter.children.length > 0">
          <coral-header level="3" :title="filter.title">{{ filter.label }}</coral-header>
          <!--coral-multi-input v-if="filter.type === 'multi-input'" :id="filter.ref" :placeholder="filter.placeholder"></coral-multi-input-->
          <AutoSuggestMultiInput v-if="filter.type === 'multi-input'" :name="filter.ref" :options="filter.data" :id="filter.ref" :placeholder="filter.placeholder" :selected="filter.selected"></AutoSuggestMultiInput>
          <coral-split-button v-if="filter.type === 'split-button'" managed>
            <coral-button v-for="(button, buttonIndex) in filter.values" :id="filter.ref + '-' + button.id" :key="buttonIndex" toggles :active="button.value">{{ button.label }}</coral-button>
          </coral-split-button>
          <span v-for="(subFilter, subFilterIndex) in filter.filters" :key="subFilterIndex">
            <label class="smaller pt5" :title="subFilter.title">{{ subFilter.label }}:</label><br v-if="subFilter.linebreak">
            <coral-combo-box v-if="subFilter.type === 'combo'" :id="subFilter.ref" :placeholder="subFilter.placeholder" :multiple="subFilter.multiple" clears></coral-combo-box>
            <coral-slider v-if="subFilter.type === 'slider'" :id="subFilter.ref" show-input-field :min="subFilter.min" :max="subFilter.max" :from="subFilter.from" :to="subFilter.to" :step="subFilter.step" range></coral-slider>
            <template v-if="subFilter.type === 'checkbox'">
              <coral-checkbox v-for="(checkbox, checkboxIndex) in subFilter.values" :id="subFilter.ref + '-' + checkbox.id" :key="checkboxIndex" :checked="checkbox.value">{{ checkbox.label }}</coral-checkbox>
            </template>
            <coral-split-button v-if="subFilter.type === 'split-button'" managed>
              <coral-button v-for="(button, buttonIndex) in subFilter.values" :id="subFilter.ref + '-' + button.id" :key="buttonIndex" toggles :active="button.value">{{ button.label }}</coral-button>
            </coral-split-button><br v-if="subFilter.afterLinebreak">
          </span>
        </template>
        <template v-else>
          <label :title="filter.title">{{ filter.label }}:</label><br v-if="filter.linebreak">
          <emerald-datetime-picker v-if="filter.type === 'date'" :id="filter.ref" range duplex :values="filter.values"></emerald-datetime-picker>
          <coral-combo-box v-if="filter.type === 'combo'" :id="filter.ref" :placeholder="filter.placeholder" :multiple="filter.multiple" clears></coral-combo-box>
          <!--coral-multi-input v-if="filter.type === 'multi-input'" :id="filter.ref" :placeholder="filter.placeholder"></coral-multi-input-->
          <AutoSuggestMultiInput v-if="filter.type === 'multi-input'" :name="filter.ref" :options="filter.data" :id="filter.ref" :placeholder="filter.placeholder" :selected="filter.selected"></AutoSuggestMultiInput>
          <coral-slider v-if="filter.type === 'slider'" :id="filter.ref" show-input-field :min="filter.min" :max="filter.max" :from="filter.from" :to="filter.to" :step="filter.step" range></coral-slider>
          <template v-if="filter.type === 'checkbox'">
            <coral-checkbox v-for="(checkbox, checkboxIndex) in filter.values" :id="filter.ref + '-' + checkbox.id" :key="checkboxIndex" :checked="checkbox.value">{{ checkbox.label }}</coral-checkbox>
          </template>
          <coral-split-button v-if="filter.type === 'split-button'" managed>
            <coral-button v-for="(button, buttonIndex) in filter.values" :id="filter.ref + '-' + button.id" :key="buttonIndex" toggles :active="button.value">{{ button.label }}</coral-button>
          </coral-split-button>
        </template>
      </li>
    </ul>
  </coral-collapse>
</template>

<script>
import AutoSuggestMultiInput from '@/components/AutoSuggestMultiInput.vue'

export default {
  name: 'FilterList',
  components: {
    AutoSuggestMultiInput
  },
  props: {
    list: Array,
    header: String,
    expanded: Boolean
  },
  data () {
    return {
    }
  },
  watch: {
    list () {
      this.initializeFilters()
    }
  },
  methods: {
    initializeFilters () {
      this.$nextTick(() => {
        //console.log('Initialize filters', this.header)
        this.list.forEach(filter => {
          if (filter.data) {
            const component = document.getElementById(filter.ref)
            if (component != null) {
              component.data = filter.data
              //console.log(filter.ref, component, component.data.length)
            }
          }
          //console.log(filter.label, filter.type, filter.defaultValues)
          this.resetComponent(filter)
          if (filter.children.length > 0) {
            filter.filters.forEach(child => {
              this.resetComponent(child)
            })
          }
        })
      })
    },
    favoriteButtonClicked (key) {
      this.$emit('favorite-clicked', {
        key
      })
    },
    resetComponent (filter) {
      let component
      switch (filter.type) {
        case 'checkbox':
          filter.values.forEach(checkbox => {
            component = document.getElementById(`${filter.ref}-${checkbox.id}`)
            component.checked = filter.defaultValues.length > 0
          })
          break
        case 'combo':
          component = document.getElementById(filter.ref)
          component.values = []
          break
        case 'date':
          component = document.getElementById(filter.ref)
          component.values = filter.defaultValues
          break
        case 'multi-input':
          component = document.getElementById(`${filter.ref}MultiInput`)
          component.values.forEach((item) => {
            component.removeByValue(item)
          })
          filter.selected.forEach(item => {
            component.add({
              value: item.id,
              label: item.label
            })
          })
          break
        case 'slider':
          component = document.getElementById(filter.ref)
          component.from = filter.defaultValues[0]
          component.to = filter.defaultValues[1]
          break
        case 'split-button':
          filter.values.forEach(button => {
            component = document.getElementById(`${filter.ref}-${button.id}`)
            component.active = button.id === filter.defaultValues
          })
          break
      }
    }
  },
  mounted () {
    this.initializeFilters()
  }
}
</script>
<style scoped>
ul.input-panel {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul.input-panel li {
  margin-bottom: 10px;
}
ul.input-panel li:last-child {
  margin-bottom: 0;
}
ul.input-panel label {
  display: inline-block;
  padding-bottom: 5px;
}
coral-multi-input::part(search) {
  min-width: 100px !important;
}
coral-combo-box {
  width: 100%;
}
.smaller {
  font-size: 0.9em;
}
.pt5 {
  padding-top: 5px;
}
.favorites {
  float: right;
  height: 12px !important;
}
</style>