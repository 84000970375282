const baseURL = process.env.VUE_APP_API_ENDPOINT
const iRPMBaseURL = process.env.VUE_APP_IRPM_BASE_URL
const iRPMAppName = process.env.VUE_APP_IRPM_APP_NAME
const iRPMProj = process.env.VUE_APP_IRPM_PROJ_NAME

const retryLimit = 3
const backoffInterval = 3000

class AppException extends Error {
  constructor (code, message) {
    const fullMessage = message ? `${code}: ${message}` : code
    super(fullMessage)
    this.name = code
    this.code = code
    this.message = fullMessage
  }
}

export default {
  async sendGetRequest (url, iteration = 1) {
    const headers = {
      'Content-Type': 'application/json'
    }
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers
      })
      if (!response.ok) {
        throw new AppException(response.status, await response.json())
      }
      return {
        status: "OK",
        data: await response.json()
      }
    }
    catch (error) {
      console.log(error, backoffInterval)
      if (iteration === retryLimit) {
        return {
          status: "ERROR",
          error,
          code: error.code
        }
      }

      return new Promise(resolve => {
        const delay = iteration * backoffInterval
        console.log(`Retry ${iteration}, delay at ${delay}`)
        setTimeout(() => {
          this.sendGetRequest(url, iteration + 1).then(resolve)
        }, delay)
      })
    }
  },
  async sendPostRequest (url, data, iteration = 1) {
    const headers = {
      'Content-Type': 'application/json'
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
      })
      if (!response.ok) {
        throw new AppException(response.status, await response.json())
      }
      return {
        status: "OK",
        data: await response.json()
      }
    }
    catch (error) {
      console.log(error, backoffInterval)
      if (iteration === retryLimit) {
        return {
          status: "ERROR",
          error,
          code: error.code
        }
      }

      return new Promise(resolve => {
        const delay = iteration * backoffInterval
        console.log(`Retry ${iteration}, delay at ${delay}`)
        setTimeout(() => {
          this.sendPostRequest(url, data, iteration + 1).then(resolve)
        }, delay)
      })
    }
  },
  retrieveMetaData () {
    return this.sendGetRequest(`${baseURL}/metadata`)
  },
  retrieveMetaDataOptions (metaData) {
    return this.sendGetRequest(`${baseURL}/metadata/${metaData}`)
  },
  retrieveData (limit, offset, options) {
    return this.sendPostRequest(`${baseURL}/data?limit=${limit}&offset=${offset}`, options)
  },
  retrieveStory (id) {
    return this.sendGetRequest(`${baseURL}/story/${id}`)
  },
  go2safe() {
    let name = encodeURIComponent(iRPMAppName)
    let callbackUrl = encodeURIComponent(window.location.origin + '/jwt/${jti}/${nounce}')
    let proj = iRPMProj
    let gate = iRPMBaseURL+'/console/safe.html'
    window.location.href = gate + '#/name/' + name + '/proj/' + proj + '/callback/' + callbackUrl
  },
  fetchJWT(jti, nounce) {
    return this.sendGetRequest(iRPMBaseURL+'/ppe/api/get-jwt/' + JSON.stringify({ jti: jti, nounce: nounce }),)
  }
}